import React, {useEffect, useState} from "react";
import "./rb_modern_header.scss";
import {handleUserSessionOnPageFocus, logout, updateSessionInfo} from "../../../services/session";
import {apiURL, idpName, imgUrl} from "../../../services/constants";
import {BrokerNav} from "./broker_nav/broker_nav";
import {BrokerLegacySettings} from "./broker_legacy_settings/broker_legacy_settings";
import {LandlordNav} from "./landlord_nav/landlord_nav";
import {MobileNav} from "./mobile_nav/mobile_nav";
import {AgentLinkNav} from "./agent_link_nav/agent_link_nav";
import {AgentHeaderBranding} from "./agent_header_branding/agent_header_branding";
import {useAgentLink} from "../../components/hooks/get_agent_link";
import {useUserData} from "../../components/hooks/use_user_data";
import {SignUpModal} from "../../components/sign_up_modal/sign_up_modal";
import {RbButton} from "../../components/rb_button/rb_button";
import {IConsumerData} from "../../../services/api";
import {useSignInForm} from "../../components/hooks/use_sign_in_form";
import {BPLNav} from "./bpl_nav/bpl_nav";

export function RBModernHeader() {
    const {agentLinkId} = useAgentLink();
    const {userData, initUserData} = useUserData();
    const [title, setTitle] = useState("");
    const [signupDialogOpen, setSignupDialogOpen] = useState(false);

    const [legacyMobileItems, setLegacyMobileItems] = useState([] as HeaderMenuItem []);
    const [brokerMobileItems, setBrokerMobileItems] = useState([] as HeaderMenuItem []);
    const [ownerMobileItems, setOwnerMobileItems] = useState([] as HeaderMenuItem []);
    const [consumerPortal, setConsumerPortal] = useState(false);
    const [agentLite, setAgentLite] = useState(true);
    const [agentLinkPortal, setAgentLinkPortal] = useState(true);
    const {SignInForm, submitForm} = useSignInForm();

    let partnerKey = "";
    try {
        partnerKey = JSON.parse($("body").attr('data-partner-config')).partner_key;
    } catch (e) {}

    const handleUserSessionUpdate = (e: CustomEvent<IConsumerData>) => {
        initUserData();
    };

    useEffect(() => {
        updateSessionInfo();
        window.addEventListener('session-update', handleUserSessionUpdate);
        window.addEventListener('focus', handleUserSessionOnPageFocus);
        setAgentLinkPortal(window.location.pathname.includes("consumer-search")) //Change consumer-search for agent-search-portal
        // setConsumerPortal(window.location.pathname.includes("consumer_search")) // ENABLE WHEN REGULAR CONSUMER PORTAL IS ENABLED
        setAgentLinkPortal(window.location.pathname.includes("agent_branded")) //Change consumer_search for agent_search_portal
        setAgentLite(window.location.pathname.includes("bpl/search")) //Change consumer_search for agent_search_portal
    }, []);

    useEffect(() => {
        if (userData.is_agent) {
            setTitle('Broker Portal')
        } else if (userData.is_owner) {
            setTitle('Landlord Portal')
        } else if (userData.is_admin) {
            setTitle('Admin Portal')
        }
    }, [userData]);

    const RbHeaderBranding = () => <div className="rb__title--container">
        {/*<div className="rb__logo" style={{background: `url(${imgUrl}/texture_packer_0-53d0aa5….png) -571px -327px no-repeat;`}} />*/}
        <img className="rb__logo" src={imgUrl + "/bp-header-logo.png"} alt="RB Logo" />
        <div className="rb__title--text">{title}</div>
    </div>

    const landingUri = `${location.pathname + location.search}`;

    const handleClick = () => {
        window.location.href = `/idp-login/${idpName}${landingUri}`;
    };


    const userAuthMobileItems = userData.id ? [
        {
            linkUrl: '/account',
            component: <div>Manage Account <i className="far fa-user" /></div>
        },
        {
            component: <div onClick={logout}>Logout <i className="fas fa-sign-out-alt" /></div>
        }] : [
            {
                component: <RbButton className="auth-button--mobile auth-button--signin" onClick={() => {handleClick()}}>Sign In </RbButton>
            },
            {
                component: <RbButton className="auth-button--mobile auth-button--signup" onClick={() => setSignupDialogOpen(true)}>Sign Up </RbButton>
        }];

    const handleSignUpClose = () => {
        setSignupDialogOpen(false);
        initUserData();
    }

    return <div className="rb__header--container">
        <div className="rb__header--content">
            {agentLinkPortal ? <AgentHeaderBranding agentId={agentLinkId}/> : <RbHeaderBranding />}
            {(userData.is_agent && !consumerPortal && !agentLinkPortal && !agentLite) ? <BrokerLegacySettings userData={userData} setMobileMenuItems={setLegacyMobileItems} className="desktop--section-container" /> : null}
            {(userData.is_agent && !consumerPortal && !agentLinkPortal && !agentLite) ? <BrokerNav setMobileMenuItems={setBrokerMobileItems} partnerKey={partnerKey} className="desktop--section-container" /> : null}
            {(userData.is_owner && !consumerPortal && !agentLinkPortal) ? <LandlordNav userData={userData} setMobileMenuItems={setOwnerMobileItems} className="desktop--section-container" /> : null}
            {(userData.is_agent && agentLite) ? <BPLNav userData={userData} setMobileMenuItems={setOwnerMobileItems} className="desktop--section-container" /> : null}
            {/*{consumerPortal ? <ConsumerNav userData={userData} setMobileMenuItems={setOwnerMobileItems} className="desktop--section-container" /> : null}*/}
            {agentLinkPortal ? <AgentLinkNav
                userData={userData}
                initUserData={initUserData}
                setMobileMenuItems={setOwnerMobileItems}
                agentId={agentLinkId}
                setSignupDialogOpen={setSignupDialogOpen}
                className="desktop--section-container" /> : null}

            <div className="mobile--nav">
                <MobileNav sections={[
                    {items: brokerMobileItems},
                    {items: legacyMobileItems},
                    {items: ownerMobileItems},
                    {items: userAuthMobileItems}
                    ]}/>
            </div>
            <SignUpModal open={signupDialogOpen} handleClose={handleSignUpClose} landingURI={landingUri} submitSignInForm={submitForm}/>
            <SignInForm />
        </div>
    </div>
}

export interface HeaderMenuItem {
    clickUrl?: string;
    component: any;
}
