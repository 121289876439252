import {useEffect, useState} from "react";

export const useAgentLink = () => {
    const [agentLinkId, setAgentLinkId] = useState(null);

    useEffect(() => {
        const agentIdContainer = document.getElementById("react_content");
        const agentId = agentIdContainer?.dataset?.agent_id;
        if (agentId) {
            setAgentLinkId(parseInt(agentId));
        }
    }, []);

    return {agentLinkId}
};