import React, {useEffect, useState} from "react";
import {agentInfoApi, IAgentInfoRes} from "../../../../services/api/agent_info";
import "./agent_header_branding.scss";
import {imgUrl} from "../../../../services/constants";

export function AgentHeaderBranding({agentId}) {
    const [agent, setAgent] = useState({} as IAgentInfoRes);

    const initAgentCard = async (agentIdParam) => {
        const agentIdToSearch = agentIdParam || agentId;
        const agentInfoRes = await agentInfoApi.get(agentIdToSearch, false);
        setAgent(agentInfoRes);
    };

    useEffect(() => {
        if (agentId > 0) {
            initAgentCard(agentId);
        }
    }, [agentId]);

    const getAgentPhoto = () => {
        const photoField = agent.photo;

        if (photoField && photoField !== "") {
            const photoFieldStr = String(photoField);
            const brokerPhoto = photoFieldStr.slice(photoFieldStr.indexOf('/rb_brokers/') + 12 , photoFieldStr.indexOf('.rbm?'));

            return <div style={{backgroundImage: `url("${imgUrl + '/home/rb-pictures/rb_brokers/' + brokerPhoto }")`}} className="agent-thumbnail" />
        } else {

            return <img className="rb-hero" src={imgUrl + "/large-beast.png"} alt="RB Logo" />
        }
    };

    return <section className="agent-header-branding--container">
        {getAgentPhoto()}
        <div className="agent-info-wrapper">
            <div>
                <span className="agent-name">{agent.full_name}</span>
            </div>
            <div>
                <span className="">{agent.phone}</span>
            </div>
            <div>
                <span className="">{agent.email}</span>
            </div>
            <div>
                <span className="">{agent.brokerage_name}</span>
            </div>
        </div>

    </section>
}
