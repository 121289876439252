import {REST} from "../../rest";
import {apiURL} from "../../constants";
const API_URL = apiURL+'/v1';

export const clientActivityApi = {

    get : async (brokerId: number, clientId?: number): Promise<clientActivityApiGetRes> => {
        const url = clientId ? `/api/client_activity/${brokerId}&client_id=${clientId}` : `/api/client_activity/${brokerId}`;

        return await REST.get(url,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).then(res => {
            return res.data.data;
        });
    },

    put : async (brokerId: number, clientActivityIds: ClientActivityId []): Promise<clientActivityApiGetRes> => {
        const url = `${API_URL}/client_activity.json`

        return await REST.put(url, {
            broker_id: brokerId,
            client_activity_ids: clientActivityIds
        }, {}).then(res => {
            return res.data.data;
        });
    },
};

interface ClientActivityId {
    id: number,
    is_seen: boolean,
    is_archived: boolean
}

export interface clientActivityApiGetRes {
    activities: ClientActivity [];
    not_seen_count: number;
}

export enum ClientActivityType {
    LEAD_ACCEPTED = "lead_accepted",
    REQUESTED_SHOWING = "requested_showing",
    SUBMITTED_CLIENT_INFO = "submitted_client_info",
    VIEWED_LISTING = "viewed_listing",
    SHARED_LISTING = "shared_listing",
    SAVED_LISTING = "saved_listing",
    CLIENT_SEARCH = "client_search",
    SENT_CLIENT_ALERT = "sent_client_alert",
    APPLICATION_SENT = "application_sent",
    APPLICATION_SUBMITTED = "application_submitted",
    APPLICATION_ACCEPTED = "application_accepted",
    APPLICATION_ACCEPTED_COND = "application_accepted_cond",
    APPLICATION_REJECTED = "application_rejected",
    CLIENT_LEASE_RENEWAL = "client_lease_renewal",
    CLIENT_SUBMITTED_INFO = "client_submitted_info",
    CLIENT_TRANSFERRED = "client_transferred",
    LIKED_LISTING = "liked_listing",
    DELETED_LISTING = "deleted_listing",
}

export interface ClientActivity {
    activity: string;
    activity_date: string;
    activity_type: ClientActivityType;
    broker_id: number;
    client_email: string;
    client_first_name: string;
    client_id: number;
    client_last_name: string;
    client_phone: string;
    communication_source: any;
    id: number;
    income: any;
    is_archived: boolean;
    is_seen: boolean;
    landlord_email: any;
    landlord_name: any;
    landlord_phone: any;
    lead_source: any;
    listing_address: any;
    listing_alert_matches: number;
    listing_alert_name: any;
    listing_id: number;
    listing_link: any;
    listing_move_in_date: any;
    listing_rent: any;
    listing_zip: any;
    max_rent: any;
    min_rent: any;
    notes: any;
    search_locations: any;
    showing_times: string;
    similar_listings_count: number;
    suggested_action: string;
    transfer_from_email: any;
    transfer_from_name: any;
    transfer_from_phone: any;
    website_url: any;
}