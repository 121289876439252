import {REST} from "../rest";

export const facebookSignIn = {
    post : async (props) => {

        const formData = new FormData();

        Object.keys(props).forEach(settingKey => {
            formData.append(settingKey, props[settingKey]);
        });

        return await REST.post(`/fb_login`,
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
            return res
        });
    }
};
