export const apiURL = gon.api_root_url;
export const oldTechURL = gon.old_tech_url;
export const rbApiUrl = `${gon.api_root_url}/rb_api/rest`;
export const listingSearchUrl = `${gon.api_root_url}/rb_api/rest/search/listing`;
export const imgUrl = gon.image_source_domain;
export const assetUrl = gon.asset_source_domain;

export const applyNowUrl = `${rbApiUrl}/applynow`;
export const lmiRentalBeastUrl = gon.lmi_rentalbeast_url;
export const googlePayMerchantId = gon.google_pay_merchantId;

const isDev = apiURL.includes("rbdev");
export const leaseMgmtUrl = `https://${
    isDev ? "rbdev." : null
}myrentpay.rentalbeast.com/sso/landlord`;
export const renterRentPayUrl = `https://${
    isDev ? "rbdev." : null
}myrentpay.rentalbeast.com/sso/renter`;
export const lmiMiniappUrl = gon.lmi_miniapp_url;
export const idpName = gon.idp_name;
export const apiBrainTreePayment = `${apiURL}/rb_api/rest/braintree`;
export const apiBrainTreePaymentToken = `${apiBrainTreePayment}/token`;
export const googleMapApiKey = gon.GOOGLE_MAPS_KEY;
export const agentApiUrl = `${rbApiUrl}/agent`;
export const localLogicSdkKey = gon.LOCAL_LOGIC_SDK_KEY;
export const universalOptionalSchoolSectionKeys = gon.universal_optional_school_section_keys;
export const universalOptionalCompensationSectionKeys = gon.universal_optional_compensation_section_keys;
export const  termsOfUseURL = 'https://www.rentalbeast.com/terms-of-use'
export const privacyPolicyURL = 'https://www.rentalbeast.com/privacy-policy'
