import { REST } from "../rest";
import { rbApiUrl } from "../constants";
import { getAuthToken } from "../session";


export const listingWithApplicationsApi = {
    getApplicationsCount: async (userId: any) => {
        const authToken = await getAuthToken();
        return await REST.get(`${rbApiUrl}/applynow/application/count/user/${userId}`,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Token token=${authToken}`,
                }
            }).then(res => {
            return res.data
        });
    }
};