import React, {useEffect} from "react";
import {IConsumerData} from "../../../../services/api";
import {idpName, renterRentPayUrl} from "../../../../services/constants";
import "./agent_link_nav.scss";
import {UserDropdown} from "../user_dropdown/user_dropdown";
import {HeaderMenuItem} from "../rb_modern_header";
import {RbButton} from "../../../components/rb_button/rb_button";

export function AgentLinkNav (props: IAgentLinkNav) {
    const {userData} = props;

    // const navComponents = userData.id ? [
    //     {
    //         clickUrl: renterRentPayUrl, component: "MyRentPay"
    //     },
    //     {
    //         clickUrl: "/payment/history", component: "Payment History"
    //     },
    //     {
    //         clickUrl: "/favorites", component: <span>Favorites <i className="fas fa-heart" style={{color: "#ED4444"}}/></span>
    //     }
    // ] : [];

    //RB:2930: Remove MyRentPay plus Payment History menu options
    const navComponents = userData.id ? [
        {
            clickUrl: "/favorites", component: <span>Favorites <i className="fas fa-heart" style={{color: "#ED4444"}}/></span>
        }
    ] : [];


    useEffect(() => {
        props.setMobileMenuItems(navComponents)
    }, [userData]);

    const landingUri = `${location.pathname + location.search}`;

    const handleClick = () => {
        window.location.href = `/idp-login/${idpName}/${landingUri}`;
    };

    return  <div className={`nav--container ${props.className}`}>
        {
            userData?.id ?
                <UserDropdown userName={userData.first_name} items={navComponents} MenuClassName="agent-search--menu-container"/> :
                <div className="auth-buttons--container">
                    <RbButton className="sign-in--button" onClick={ () => {handleClick()} }>SIGN IN</RbButton>
                    <RbButton className="sign-up--button" onClick={() => props.setSignupDialogOpen(true)}>SIGN UP</RbButton>
                </div>
        }
    </div>
}

interface IAgentLinkNav {
    userData: IConsumerData;
    initUserData: () => void;
    setMobileMenuItems: (args: HeaderMenuItem []) => any;
    className?: string;
    agentId: number;
    setSignupDialogOpen: (newVal: boolean) => any;
}
