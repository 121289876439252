import axios from "axios";
import uuidv4 from "uuid/v4";

export const currentSessionId = uuidv4();

/**
 * Gets the token from the local sessionStorage, if empty, requests a new one
 * @returns {Promise<string>}
 */
export const getAuthToken = async () => {
    return getSessionParam('auth-token');
};

export const getUserId = async () => {
    return await getSessionParam('user-id');
};

//added to support RB-602
export const getCompanyTitle =() =>{
    return sessionStorage.getItem("company_title");
}

export const getCompanyKey =() =>{
    return sessionStorage.getItem("partner_key");
}

export const emailUsernameNeeded = async () => {
    return await getSessionParam('need_email_username');
};

const getSessionParam = async (requestedParamName) => {
    return sessionStorage.getItem(requestedParamName);
};

export const handleUserSessionOnPageFocus = async() => {
    const currentUserId = sessionStorage.getItem('user-id');
    // await updateSessionInfo();
    const userIsNull = currentUserId === null || currentUserId === 'null';
    // console.log("Current User", currentUserId)
    if (!userIsNull) { //If the userId exists, it means there was a session, and we have to check
        // console.log("User was not null, verifying...")
        const sessionInfoRes = await getSessionInfo();
        // console.log({sessionInfoRes})
        const sessionData = sessionInfoRes.data.data;
        if (sessionData['user-id'] === null) { //If the user was logged in, but now is not
            // console.log("user was logged in bot now is not")
            sessionStorage.clear();
            redirectUserToUrl();
        } else if (sessionData['user-id'] != currentUserId) { //If a user was logged in, but now there's another user
            // console.log('User was logged in, but theres a different user now')
            redirectUserToUrl();
        } else {
            // console.log("User logged in. No action required")
        }
    }
    //Otherwise, if there is no user-id we assume the user hasn't logged in yet.
}

const redirectUserToUrl = () => {
    const isAgent = getSessionParam('is-agent')
    const isOwner = getSessionParam('is-owner')
    const isAdmin = getSessionParam('is-admin')

    const redirectUrl = getRedirectUrlForUser(isAgent, isOwner, isAdmin);

    if (redirectUrl) { //If the user should be redirected based on current URL and user type
        window.location.replace(redirectUrl);
    }
}

const getRedirectUrlForUser = (isAgent, isOwner, isAdmin): string => {
    //The redirect logic should go here.
    const isConsumerLegacyPortal = window.location.pathname.match('apartments/for_rent');
    const isConsumerPortal = window.location.pathname.includes("agent_branded");
    if (!(isConsumerPortal || isConsumerLegacyPortal)) {
        return "/"
    }
}

/**
 * Gets a fresh token from the Rails BE, and caches to SessionStorage
 * @returns {Promise<number>}
 */
export const updateSessionInfo = async () => {
    const res = await getSessionInfo();
    const sessionData = res.data.data;
    Object.keys(sessionData).forEach(key => {
        sessionStorage.setItem(key, sessionData[key]);
    });
    const sessionEvent = new CustomEvent("session-update", {detail: sessionData});
    window.dispatchEvent(sessionEvent);
    return sessionData;
};

const getSessionInfo = async () => {
    const url = window.location.origin+'/get-session-info';
    return await axios.get(url);
}

export const logout = () => {
    window.sessionStorage.clear();
    if (window.location.pathname.includes('/applications')) {
        window.location.replace('/signout');
    } else {
        window.location.replace(`/signout?redirect=${location.origin + location.pathname + location.search}`);
    }
}