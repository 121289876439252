import {REST} from "../rest";
import {apiURL} from "../constants";
import * as Querystring from "querystring";
import {getAuthToken, getUserId} from "../session";

export const learnUpon = {
    post : async (props: ILearnUponPost) => {
        const authToken = await getAuthToken();
        const formData = Querystring.stringify(props as any);

        return await REST.post(`${apiURL}/v1/learn_upon_sqsso.json`,
            formData, {
                headers: {
                    'Authorization': `Token token=${authToken}`,
                    'Content-Type': 'application/x-www-form-urlencoded;  charset=utf-8',
                    'cache-control': 'no-cache'
                }
            }).then(res => {
            return res
        });
    }
};

export const docebo = {
    post : async (props: IDoceboPost) => {
        const authToken = await getAuthToken();
        const formData = Querystring.stringify(props as any);

        return await REST.post(`${apiURL}/v1/docebo_sso.json`,
            formData, {
                headers: {
                    'Authorization': `Token token=${authToken}`,
                    'Content-Type': 'application/x-www-form-urlencoded;  charset=utf-8',
                    'cache-control': 'no-cache'
                }
            }).then(res => {
            return res
        });
    }
};

interface ILearnUponPost {
    portal: string;
    user_id: number;
}

interface IDoceboPost {
    user_id?: number;
}

export const getLearnUponUrl = async () => {
    const userId = parseInt(await getUserId());
    const res = await learnUpon.post({
        portal: "full",
        user_id: userId,
    });
    return res.data.data.sso_url;
};

export const getDoceboUrl = async () => {
    const user_id = parseInt(await getUserId());
    const res = await docebo.post({ user_id })
    return res.data.data.sso_url;
}

export const handleEducationTabClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.replace(await getLearnUponUrl());
    // TODO: implement something to display error text "Cannot open Education page, please contact support."
};