import React, {useRef, useState} from "react";
import "./user_dropdown.scss";
import {Menu, MenuItem} from '@material-ui/core';
import {logout} from "../../../../services/session";

interface UserDropdownProps {
    userName: string;
    items?: {
        component: any, clickUrl?: string
    } [];
    MenuClassName?: string;
}

export function UserDropdown(props: UserDropdownProps) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const handleManageAccount = () => {
        window.location.href = "/account"
    }

    return <React.Fragment>
        <div className="dropdown--container" onClick={handleClick}>
            <span>{props.userName}</span>
            <i className="fas fa-cog"></i>
        </div>
            <Menu
                open={open}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                onClose={handleClose}
                autoFocus={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className={props.MenuClassName || ""}
            >
                {
                    props.items?.map(menuItem => {
                        return <MenuItem className="menu-item" key={menuItem.clickUrl} onClick={() => menuItem.clickUrl && window.open(menuItem.clickUrl)}>
                            <div className="menu-item--container">
                              {menuItem.component}
                            </div>
                        </MenuItem>
                    })
                }
                {
                    props.items?.length > 0 ? <div className="dropdown-nav--separator"/> : null
                }
                <MenuItem className="menu-item" onClick={handleManageAccount}><div className="menu-item--container"><span>Manage Account</span><i className="far fa-user" /></div></MenuItem>
                <MenuItem className="menu-item" onClick={logout}><div className="menu-item--container"><span>Logout</span> <i className="fas fa-sign-out-alt" /></div></MenuItem>
            </Menu>
    </React.Fragment>
}