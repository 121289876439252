import axios from 'axios';
import {currentSessionId, getAuthToken} from "./session";
import {rbLoggerApi} from "./rb_logger";

const logAndReject = (url, params, method, response) => {
    logRequest(url, params, method, response);
    return Promise.reject(response)
};

const handleRBResponse = (response, params, url, method, logReq = false, logLabel = "front_end_request") => {
    try {
        if (logReq) logRequest(url, params, method, {label: logLabel});
    } catch (e) {
        console.error('Could not log request', e);
    }
    if (response.data && (response.data.data || response.data.code)) {
        if (response.data.code !== 200 && response.status !== 200) {
            return logAndReject(url, params, method, response);
         }
    } else if (response.status !== 200 && response.status !== 204 && response.status !== 202 && response.status !== 201) {
        return logAndReject(url, params, method, response);
    }
    return response
};

const authorizeRequest = async (reqParams) => {
    const authToken = await getAuthToken();
    const params = {...reqParams};
    params["headers"] = params.headers || {};
    params.headers["Authorization"] = `Token token=${authToken}`;
    return params;
};

const logRequest = (url: string, params: any, method, data = {}) => {
    rbLoggerApi.post({
        session_uuid: currentSessionId, app_namespace: "rest_client", log: {
            request_url: url,
            params: {...params},
            method,
            data
        }
    });
};

export const REST = {
    get: async (url, params = {}, logReq = false, logNamespace = 'front_end_request') => {
        const authorizedParams = await authorizeRequest(params);
        return axios.get(url, authorizedParams).then(res => {
            return handleRBResponse(res, params, url, 'get', logReq, logNamespace);
        }).catch(e => logAndReject(url, params, 'get', e));
    },
    delete: async (url, params = {}, logReq = false, logNamespace = 'front_end_request') => {
        const authorizedParams = await authorizeRequest(params);
        return axios.delete(url, authorizedParams).then(res => {
            return handleRBResponse(res, params, url, 'delete', logReq, logNamespace);
        }).catch(e => logAndReject(url, params, 'delete', e));
    },
    put: async (url, data = {}, params = {}, logReq = false, logNamespace = 'front_end_request') => {
        const authorizedParams = await authorizeRequest(params);
        return axios.put(url, data, authorizedParams).then(res => {
            return handleRBResponse(res, params, url, 'put', logReq, logNamespace);
        }).catch(e => logAndReject(url, params, 'put', e));
    },
    post: async (url, data: any = {}, params = {}, logReq = false, logNamespace = 'front_end_request') => {
        const authorizedParams = await authorizeRequest(params);
        return axios.post(url, data, authorizedParams).then(res => {
            return handleRBResponse(res, params, url, 'post', logReq, logNamespace);
        }).catch(e => logAndReject(url, params, 'post', e));
    }
};
