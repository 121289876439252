import React from "react";
import "./rb_button.scss";

export function RbButton(props: IRbButton) {
    const {onClick, children, disabled, className = "", style, type = "button"} = props;
    return <button
        className={`rb-button btn ${className}`}
        onClick={onClick}
        type={type}
        disabled={disabled}
        style={style}
    >
        {children}
    </button>
}

interface IRbButton {
    onClick?;
    children?;
    disabled?:boolean;
    className?: string;
    style?;
    type?: 'submit' | 'reset' | 'button';
}