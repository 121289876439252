import React, {useEffect, useState} from "react";
import {IConsumerData} from "../../../../services/api";
import {leaseMgmtUrl} from "../../../../services/constants";
import "./landlord_nav.scss";
import {NavButton} from "../nav_button/nav_button";
import {UserDropdown} from "../user_dropdown/user_dropdown";
import {HeaderMenuItem} from "../rb_modern_header";

export function LandlordNav (props: ILandlordNav) {
    const {userData} = props;
    const [showButton, setShowButton] = useState(true);

    const hideMyApplicationsTab = () => {
        if( navigator.language === 'en-CA')
            setShowButton(!showButton);
      };

    useEffect(() => {
        hideMyApplicationsTab();
    }, [])
    useEffect(() => {
        props.setMobileMenuItems([
            {
                clickUrl: "/landlord_portal/listings", component: "My Rentals"
            },
            {
                clickUrl: "/apply_now_landlord/applications", component: "My Applications"
            }
        ])
    }, [])

    if(showButton){
    return  <div className={`llp-header--nav-container ${props.className}`}>
        <NavButton url="/landlord_portal/listings" label="My Rentals" />
        <NavButton url="/apply_now_landlord/applications" label="My Applications" />
        <UserDropdown userName={userData.first_name}/>
    </div>
    } else {
        return  <div className={`llp-header--nav-container ${props.className}`}>
        <NavButton url="/landlord_portal/listings" label="My Rentals" />
        <UserDropdown userName={userData.first_name}/>
    </div>
    }
}

interface ILandlordNav {
    userData: IConsumerData;
    setMobileMenuItems: (args: HeaderMenuItem []) => any;
    className?: string;
}