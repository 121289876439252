import React from "react";
import "./nav_button.scss";

interface NavButtonProps {
    url: string;
    label: string;
    border?: boolean;
}

export function NavButton(props: NavButtonProps) {
    const {url, label, border = true} = props;
    const className = `nav-button ${border && 'border'}`
    return <a className={className} href={props.url}>{props.label}</a>
}