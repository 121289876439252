import {AgentHeaderBranding} from "./agent_header_branding/agent_header_branding";
import React, {useEffect} from "react";
import {useParams} from "react-router";
import {handleUserSessionOnPageFocus, updateSessionInfo} from "../../../services/session";

export function CmaHeader () {
    const {agentId} = useParams()

    useEffect(() => {
        updateSessionInfo();
        window.addEventListener('focus', handleUserSessionOnPageFocus);
    }, []);

    return <div className="container">
        <AgentHeaderBranding agentId={agentId}/>
    </div>
}