import * as Yup from "yup";
import {state_abbreviations, states, canadian_provinces} from "./states";

export const requiredFirstNameValidation = Yup.string().required('First Name is required');
export const requiredLastNameValidation = Yup.string().required('Last Name is required');
export const requiredEmailValidation = Yup.string().email('Please enter a valid email address').required('Email is required');
export const emailValidation = Yup.string().email('Please enter a valid email address');
export const streetAddressValidation = Yup.string().min(5, 'Please enter a valid street address');
export const unitCountValidation = Yup.number().positive().integer().default(1);
export const yearValidation = Yup.number().positive().integer();
export const stateAbbrValidation = Yup.string()
    .test('isStateSelected', "Please select a value", val => {
        return state_abbreviations.includes(val) ;
    });
export const stateValidation = Yup.string()
    .test('isStateSelected', "Please select a value", val => {
        return states.includes(val) || canadian_provinces.includes(val);
    });
export const zipCodeValidation = Yup.string().matches( /^\d{5}(?:[-\s]\d{4})?$/, "Please enter a valid zip code");

// --- Canadian Postal Code Pattern ---
// * based from Canada Post Corporation (CPC)
// * accepts 6-digit character
// * has a pattern of `ANA NAN` where 'A' represents as 'Alphabetic character' and 'N' as 'Numeric character'
// * (e.g., K1A 0T6)
// * The postal code uses 18 alphabetic characters and 10 numeric characters
// * Eight alphabetic characters (D, F, I, O, Q, U, W and Z) are not in use at the present time
export const postalCodeValidation = Yup.string().matches( /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/i, `Please enter a valid postal code`);
// --- END ---

export const phoneValidation = Yup.string().matches(/^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/, 'Please enter a valid phone number');
export const requiredPhoneValidation = phoneValidation.required('Please enter a phone number');

export const Validations = {
    name: Yup.string()
        .required('Required'),
    phone: phoneValidation
        .required('Please enter a phone number'),
    email: Yup.string()
        .required('Required')
        .email(),
    password: Yup.string()
        .required('Required')
        .min(8)
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
    //This is a function since the field name depends on the schema
    passwordConfirmation: (passwordFieldName: string = 'password') => Yup.string()
        .required('Required')
        .oneOf([Yup.ref(passwordFieldName), null], 'Passwords must match')
};

export const requiredAddressValidationSchema = Yup.object().shape({
    address: streetAddressValidation.required('Required'),
    city: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    state: stateValidation,
    zip_code: zipCodeValidation.required('Required'),
});

export const zipOrPostalValidation = (validationSchema, countryCode= 'US', required = false, key = 'zip_code') => {
    if (countryCode === 'CA') {
        if (required) {
            return validationSchema.shape({ [key]: postalCodeValidation.required('Required') });
        } else {
            return validationSchema.shape({ [key]: postalCodeValidation });
        }
    }
    return validationSchema
}

export const isEmptyOrNull = value => value === '' || value === null || String(value).toLowerCase() === 'null';
