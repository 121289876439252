// Rename isFullAgent to isProAgent
export const isFullAgent = (agentInfo: any) => {
    return ['pro', 'pro_search', 'pro_limited', 'pro_admin', 'pro_appraiser'].includes(agentInfo?.agent_type)
};

export const isGatewayAgent = (agentInfo: any) => {
    return ['gateway', 'gateway_search', 'gateway_admin', 'gateway_limited', 'gateway_appraiser'].includes(agentInfo?.agent_type)
};

export const isMlsAgent = (agentInfo: any) => {
    return isGatewayAgent(agentInfo);
};

export const isRBListing = (listing: any) => {
    // return listing.disclaimer?.rb_verified === true;
    return listing.listing_source_type === "RB";
};

export const isMLSListing = (listing: any) => {
    return listing.listing_source_type === "MLS";
};

export const isRbgListing = (listing: any) => {
    return listing.listing_source_type === "RBG";
};

export const isMlsAgentAndNonMlsListing = (listing: any, agentInfo: any) => {
    return isMlsAgent(agentInfo) && isRBListing(listing)
};

export const isMlsAgentAndMlsListing = (listing: any, agentInfo: any) => {
    return isMlsAgent(agentInfo) && isMLSListing(listing)
};
