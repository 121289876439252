export enum RB_FEATURES {
            DASHBOARD = "dashboard",
            FULL_SEARCH = "full_search",
            FULL_SEARCH_OLD = "full_search_old",
            LIGHT_SEARCH = "light_search",
            CLIENTS_LANDLORD ="clients_landlord",
            CLIENTS_RENTER = "clients_renter",
            ACTIVITY_HUB = "activity_hub",
            APPLICATIONS = "applications",
            ALERTS_SHOWINGS = "alerts_showings",
            MY_LISTINGS = "my_listings",
            EDUCATION = "education",
            USER_SETTINGS = "user_settings",
            COMMISSION_ESTIMATOR = "commission_estimator",
            VIRTUAL_ASSISTANT = "virtual_assistant",
            ALL_MLS_LISTINGS = "all_mls_listings",
            ALL_RB_LISTINGS = "all_rb_listings",
            ALL_PARTNER_LISTINGS = "all_partner_listings",
            LEAD_CONFIG = "lead_config",
            MAP_SEARCH_VIEW = "map_search_view",
            SCHOOL_LAYER = "school_layer",
            POINTS_OF_INTEREST = "points_of_interest_layer",
            SCHOOL_DISTRICT_LAYER = "school_district_layer",
            SUBDIVISION_LAYER = "subdivision_layer",
            UPGRADE = 'upgrade'
        }
