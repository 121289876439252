import {apiURL} from "../../../../services/constants";
import React, {useEffect, useState} from "react";
import {getUserId} from "../../../../services/session";
import {clientActivityApi} from "../../../../services/rails_api/broker_portal/client_activity";
import "./bottom_nav.scss"
import {HeaderMenuItem} from "../rb_modern_header";
import {applyNowAvailableApi} from "../../../../services/api/applynow_available";
import {getLearnUponUrl, handleEducationTabClick} from "../../../../services/api/learn_upon_sqsso";

enum paths {
    "/broker_portal/listing_search" = "Search",
    "/broker_portal/listings" = "My Listings",
    "/broker_portal/landlord_clients" = "Clients",
    "/broker_portal/applications" = "Applications",
}

export function BrokerNav(props: {setMobileMenuItems: (args: HeaderMenuItem []) => any, className: string, partnerKey: string}) {
    const [redDotCounter, setRedDotCounter] = useState(0);
    const [currentPath, setCurrentPath] = useState("");
    const [applyNowEnabled, setApplyNowEnabled] = useState(false);

    const initMenuItems = async () => {
        const applyNowEnabledRes = await applyNowAvailableApi.getApplyNowAvailable(props.partnerKey);
        setApplyNowEnabled(applyNowEnabledRes.apply_now_enabled);
        const menuItems: HeaderMenuItem [] = [{
                    clickUrl: apiURL + "/broker-home",
                    component: "Home"
                 },
                {
                    clickUrl: apiURL + "/broker-search",
                    component: "Search"
                }
            ]
        if (true) {
            menuItems.push(
                {
                    clickUrl: apiURL + "/broker-alerts",
                    component: "Alerts/Showings"
                },
                {
                    clickUrl: apiURL + "/client-list",
                    component: <div>
                        <span>Clients</span>
                        <div className="red-dot-header">{redDotCounter}</div>
                    </div>
                },
                {
                    clickUrl: "/broker_portal/listings",
                    component: "My Listings"
                },
                {
                    clickUrl: await getLearnUponUrl(),
                    component: "Education"
                }
            );
        }
        if (applyNowEnabledRes.apply_now_enabled) {
            menuItems.push({
                    clickUrl: "/broker_portal/applications",
                    component: "Applications"
                })
        }
        props.setMobileMenuItems(menuItems);
    };

    const initPathName = () => {
        for (let path in paths) {
            if(window.location.pathname.search(path) >= 0) {
                setCurrentPath(paths[path])
            }
        }
    };

    const initRedDot = async () => {
        const brokerId = parseInt(await getUserId());
        const response = await clientActivityApi.get(brokerId);
        setRedDotCounter(response.not_seen_count);
    };

    const isActiveTab = (tab: string) => {
        return (currentPath === tab) ? " active-tab" : "";
    };

    useEffect(() => {
        initRedDot();
        initPathName();
        initMenuItems();
    }, []);

    return <div className={`rb__nav--container ${props.className}`}>
        <a href={apiURL + "/broker-home"} className={"rb__nav--item " + isActiveTab("Home")}>Home</a>
        <a href={apiURL + "/broker-search"} className={"rb__nav--item " + isActiveTab("Search")}>Search</a>
        <a href={apiURL + "/broker-alerts"}
           className={"rb__nav--item " + isActiveTab("Alerts/Showings")}>Alerts/Showings</a>
        <a href="/broker_portal/renter_clients" className={"rb__nav--item " + isActiveTab("Clients")}>
            <span>Clients</span>
            {redDotCounter > 0 ? <div className="red-dot-header">{redDotCounter}</div> : null}
        </a>
        {
            applyNowEnabled ?
            <a href={"/broker_portal/applications"}
               className={"rb__nav--item " + isActiveTab("Applications")}>Applications</a> : null
        }
        <a href={"/broker_portal/listings"} className={"rb__nav--item " + isActiveTab("My Listings")}>My Listings</a>
        <a onClick={(e) => handleEducationTabClick(e)}
           className={"rb__nav--item " + isActiveTab("Education")}>Education</a>
    </div>
}