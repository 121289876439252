import {applyNowUrl} from "../constants";
import {REST} from "../rest";

export const applyNowAvailableApi = {
    get : async ({listingId, portal, partnerKey}) => {

        return await REST.get(`${applyNowUrl}/available/listing/${listingId}`,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    "portal": portal,
                    "partner_key": partnerKey
                }
            }).then(res => {
            return res.data
        }).catch(e => {
            console.error(e);
        });
    },

    getApplyNowAvailable : async (partnerKey = "default") => {
        return await REST.get(`/applynow/is_apply_now_enabled`, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                "company_key": partnerKey,
            }
        }).then(res => {
            return res.data
        }).catch(e => {
            console.error(e);
        });
    }
};