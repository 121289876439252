import React, {useRef} from "react";

export interface ISignInSubmitForm {
    actionFormRoute: string;
    landingURI: string;
    listingId?: number;
}

export function useSignInForm() {

    const signInFormRef = useRef(null);
    const favoritesInputRef = useRef(null);
    const landingInputRef = useRef(null);

    const SignInForm = () => {
        return (
            <form ref={signInFormRef} method="POST" style={{display: "none"}}>
                <input type="hidden" name="add_to_favorite" ref={favoritesInputRef} />
                <input type="hidden" name="landing_uri" ref={landingInputRef} />
            </form>
        )
    };

    const submitForm = (props: ISignInSubmitForm) => {
        const {listingId, landingURI, actionFormRoute} = props;

        favoritesInputRef.current.value = listingId;
        landingInputRef.current.value = landingURI;

        signInFormRef.current.action = actionFormRoute;
        signInFormRef.current.submit();
    };

    return {
        SignInForm: SignInForm,
        submitForm: submitForm,
    }
}