import {useEffect, useState} from "react";
import {getConsumerData, IConsumerData} from "../../../services/api";

export function useUserData(initValue = {}) {

    const [userData, setUserData] = useState(initValue as IConsumerData);

    const initUserData = async () => {
        const consumerData = await getConsumerData();
        if (consumerData) {
            setUserData(consumerData);
        }
    };

    return {userData, initUserData};
}