import {apiURL} from "../constants";
import {REST} from "../rest";
import {RB_FEATURES} from "../../src/components/admin/types";

export const partnerConfigApi = {
    get: async (partnerKey, reqResources: PartnerConfigResourcesType [], rbPortal?: string, pageKey?: string) => {
        const resources = JSON.stringify(reqResources);
        const baseUrl = `${apiURL}/v2/partner_config.json`
        const regParams = `partner_key=${partnerKey}&resources=${encodeURIComponent(resources)}`
        const udfParams = (rbPortal && pageKey) ? `&rb_portal=${rbPortal}&page_key=${pageKey}` : ''
        let url = `${baseUrl}?${regParams}${udfParams}`
        return await REST.get(url
            , {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
            .then(res => {
                return res.data.data
            });
    },
    put: async (partnerKey, updatedSettings: IPartnerConfigSettings) => {
        const formData = new FormData();

        Object.keys(updatedSettings).forEach(sectionKey => {
            formData.append(sectionKey, JSON.stringify(updatedSettings[sectionKey]))
        });

        return await REST.put(`${apiURL}/v2/partner_config.json?partner_key=${partnerKey}`,
            formData
            , {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        )
            .then(res => {
                return res.data.data
            });
    },
    post: async (partnerKey) => {
        const formData = new FormData();
        formData.append('new_partner_key', partnerKey);

        return await REST.post(`${apiURL}/v2/partner_config.json`,
            formData
            , {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
            return res.data.data
        });
    },
};

export interface IPartnerConfigSettings {
    gui_settings: IGuiSettings;
    feature_access: IFeatureAccess ;
    front_end_settings: { white_labeled_broker_portal: boolean } ;
    features: RB_FEATURES [];
}

export interface WhiteLabelStyles {
    bpWlFooter: {
        main: {
            backgroundColor: string;
            color: string;
            linkColor: string;
        },
        legal: {
            backgroundColor: string;
            color: string;
            linkColor: string;
        }
    };
    bpWlHeader: {
        backgroundColor: string;
        backgroundPosition: string;
        color: string;
        backgroundImage: string;
    };
    bpWlUserAccount: {
        linkColor: string;
        color: string;
    };
}

export interface WLFooterSection {
    title: string;
    items: {label: string, url: string} [];
}

export interface WhiteLabelContent {
    footer: {
        mainSection: WLFooterSection [];
        legalNotice: string;
    }
}

export interface IFeatureAccess {
    roles: {
        [key: string]: RB_FEATURES []
    }
}

export interface BPGuiSettings {
    styles: WhiteLabelStyles
    content: WhiteLabelContent
}

export interface IGuiSettings {
    title: string;
    url: string;
    logo_path: string;
    header: {
        background_color: string;
        icons_color: string;
        tab_title_color: string;
        tab_title_color_hover: string;
        tab_title_background_hover: string;
        tab_dropdown_bg_color: string;
        tab_items_color: string;
        tab_items_color_hover: string;
        logo_position: "left" | "center" | "right";
        tabs: ISection [];
        top_bar_show: boolean;
        top_bar_link: string;
        top_bar_link_label: string;
    };
    footer: {
        background_color: string;
        section_title_color: string;
        items_color: string;
        copyright: string;
        sections: ISection [];
    };
    custom_styles?: {},
    bp_ui_settings?: BPGuiSettings
}

export interface ISection {
    name: string;
    link?: string;
    items?: IMenuItem [];
    links?: any;
}

export interface IMenuItem {
    label: string;
    link?: string;
}

export type PartnerConfigResourcesType = "back_end_settings" | "gui_settings" | "front_end_settings" | "jarvis_settings" | "broker_portal_settings" |"partners_list" | "feature_access" | "status_tracker_config" | "locale_settings" | "udf" | "partner_company_optional_fields";
