import {apiURL} from "../../../../services/constants";
import React, {useEffect} from "react";
import {IConsumerData} from "../../../../services/api";
import "./broker_legacy_settings.scss"
import {HeaderMenuItem} from "../rb_modern_header";
import {logout} from "../../../../services/session";

interface BrokerLegacySettingsProps {
    userData: IConsumerData;
    setMobileMenuItems: (args: HeaderMenuItem []) => any;
    className: string;
    whiteLabel?: boolean;
}

// set user identifiers into general session to be used by GTM/Drift
function setSessionForDrift (props: BrokerLegacySettingsProps){
    sessionStorage.setItem("id",(props.userData.id).toString());
    sessionStorage.setItem("first_name",props.userData.first_name);
    sessionStorage.setItem("last_name",props.userData.last_name);
    sessionStorage.setItem("email",props.userData.email);
    sessionStorage.setItem("phone",props.userData.phone);
}

//TODO: Need to check if the user is logged in to show either the sign in buttons or the user settings menu
export function BrokerLegacySettings (props: BrokerLegacySettingsProps) {
    const {userData, setMobileMenuItems, className} = props;
    setSessionForDrift(props); //support of Rb-1343    

    useEffect(() => {
        setMobileMenuItems([
            {
                clickUrl: props.whiteLabel ? '/agent/settings' : apiURL + "/broker-settings",
                component: <span><i className="fas fa-cogs" /> Settings</span>
            },
            {
                component:  <span className="rb__logout" onClick={logout}><i className="fas fa-sign-out-alt" /> Log out</span>
            }
        ])
    }, []);

    return <div className={`rb__user-settings ${className}`}>
        <div className="rb__user-settings--row">
            <span className="rb__user-settings--name">{userData.first_name} {userData.last_name}</span>
            <span className="rb__header--divider">|</span>
            <a href={props.whiteLabel ? '/agent/settings' : apiURL + "/broker-settings"}>Settings</a>
            <span className="rb__header--divider">|</span>
            <span className="rb__logout" onClick={logout}>Log out</span>
        </div>
    </div>
}