import { REST } from "../rest";
import { rbApiUrl } from "../constants";
import { getAuthToken } from "../session";


export const listingWithAlertsApi = {
    get: async (userId: any, partnerKey: any, alertStatus?: string) => {
        const authToken = await getAuthToken();
        return await REST.get(`${rbApiUrl}/listing_alert/list/user?user_id=${userId}&partner_key=${partnerKey}&alert_status=${alertStatus}`,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Token token=${authToken}`,
                }
            }).then(res => {
            return res.data
        });
    },
    getAlertsCount: async (userId: any, partnerKey: any, alertStatus?: string) => {
        const authToken = await getAuthToken();
        return await REST.get(`${rbApiUrl}/listing_alert/count/user?user_id=${userId}&partner_key=${partnerKey}&alert_status=${alertStatus}`,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Token token=${authToken}`,
                }
            }).then(res => {
            return res.data
        });
    },
    getActiveListingAlert: async (listingId) => {
        return await REST.get(`${rbApiUrl}/listing_alert/list/listing?listing_id=${listingId}&alert_status=active`).then((res) => res.data)
    }
};