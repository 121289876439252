import {apiURL} from "./constants";
const API_URL = apiURL+'/v1';
import axios from "axios";

export const rbLoggerApi = {
    post : async (dataToLog: IDataToLog) => {
        const formData  = new FormData();
        formData.append('category', 'front_end_log');
        formData.append('data', JSON.stringify({testKey: "testData"}));
        formData.append('tags', JSON.stringify(['front_end']));

        return await axios.post(`${API_URL}/rb_logger.json`, {category: 'front_end_log',
        data: dataToLog, tags: ['front_end']}, {
            data: null,
            headers: {
                'Authorization': `Basic RzBUVWFxZmJZOWRxMEN3eDp4d1BacDdDV1VWTlN5ZWVj`,
                'Content-Type': 'application/json',
            },
        }).then(res => {
            // console.log("logging res", res);
        });
    }
};

export interface IDataToLog {
    session_uuid: string;
    app_namespace: string;
    log: any;
}