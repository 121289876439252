import React, {useEffect, useState} from "react";
import {IConsumerData} from "../../../../services/api";
import "./bpl_nav.scss";
import {NavButton} from "../nav_button/nav_button";
import {UserDropdown} from "../user_dropdown/user_dropdown";
import {HeaderMenuItem} from "../rb_modern_header";
import {getLearnUponUrl, getDoceboUrl} from "../../../../services/api/learn_upon_sqsso";

export function BPLNav (props: ILandlordNav) {
    const {userData} = props;
    const [links, setLinks] = useState([]);

    const initMenuNav = async () => {
        const localLinks = [
            {
                clickUrl: "/bpl/home", component: "Home"
            },
            {
                clickUrl: "/bpl/search", component: "Search"
            },
            {
                clickUrl: "/free_tenant_screening", component: "Free Applications"
            },
            {
                // clickUrl: await getLearnUponUrl(), component: "Education"
                clickUrl: await getDoceboUrl(), component: "Education"
            },
            {
                clickUrl: "/unlock-the-beast?tab_name=unlock_the_beast&user_name=asewasdf%20asdfasdf", component: "Unlock the Beast"
            }
        ];
        props.setMobileMenuItems(localLinks);
        setLinks(localLinks);
    }

    useEffect(() => {
        initMenuNav();
    }, [])
    return  <div className={`llp-header--nav-container ${props.className}`}>
        {
            links.map(link => {
                return <NavButton url={link.clickUrl} label={link.component} border={false}/>
            })
        }
        <UserDropdown userName={userData.first_name} items={[{clickUrl: "/bpl/applications", component: "My Applications"}]}/>
    </div>
}

interface ILandlordNav {
    userData: IConsumerData;
    setMobileMenuItems: (args: HeaderMenuItem []) => any;
    className?: string;
}
