import React, {useEffect, useState} from "react";
import {getUserId} from "../../../../services/session";
import {clientActivityApi} from "../../../../services/rails_api/broker_portal/client_activity";
import "./dynamic_nav.scss"
import {HeaderMenuItem} from "../rb_modern_header";
import {RB_FEATURES} from "../../../components/admin/types";
import {applyNowAvailableApi} from "../../../../services/api/applynow_available";
import {featureSettingsApi, FeaturesMenuList, FeatureTypes} from "../../../../services/api/feature_settings";
import {getDoceboUrl} from "../../../../services/api/learn_upon_sqsso";
import { isFullAgent } from "../../../utils/listings";
import axios from 'axios';
import { listingWithAlertsApi } from "../../../../services/api/property_list";
import { listingWithApplicationsApi } from "../../../../services/api/applications";


interface IFeatureLink {
    clickUrl?: string;
    component: any;
    open_in_new_tab?: boolean;
    id: RB_FEATURES;
    is_modal?: boolean;
}

const isClientFeature = (featureName: RB_FEATURES) => {
    return featureName === RB_FEATURES.ACTIVITY_HUB || featureName === RB_FEATURES.CLIENTS_LANDLORD || featureName === RB_FEATURES.CLIENTS_RENTER
};

export function DynamicNav(props: DynamicNavProps) {

    const [currentPath, setCurrentPath] = useState("");
    const [allowedFeatures, setAllowedFeatures] = useState([] as IFeatureLink[]);
    const [labels, setLabels] = useState({} as FeaturesMenuList);
    // const defaultClientFeature = RB_FEATURES.CLIENTS_LANDLORD;
    let defaultClientFeature = RB_FEATURES.CLIENTS_LANDLORD;

    const handleEducationTabClick = async () => {
        // --- RB-1289: Update from LearnUpon to Docebo SSO ---
        // return await getLearnUponUrl()
        // --- END ---
        return await getDoceboUrl()
    };

    const featureLinks = {
        [RB_FEATURES.DASHBOARD] : {clickUrl: "/broker_portal/dashboard"},
        [RB_FEATURES.FULL_SEARCH] : {clickUrl: "/broker_portal/listing_search"},
        [RB_FEATURES.ALERTS_SHOWINGS] : {clickUrl: "/broker_portal/alerts"},
        // [RB_FEATURES.ALERTS_SHOWINGS] : {clickUrl: `${oldTechURL}/broker-alerts`},
        [RB_FEATURES.APPLICATIONS] : {clickUrl: "/broker_portal/applications"},
        [RB_FEATURES.MY_LISTINGS] : {clickUrl: "/broker_portal/listings"},
        [RB_FEATURES.CLIENTS_LANDLORD] : {clickUrl: "/broker_portal/landlord_clients"},
        [RB_FEATURES.CLIENTS_RENTER] : {clickUrl: "/broker_portal/renter_clients"},
        // [RB_FEATURES.CLIENTS_RENTER] : {clickUrl: "/broker_portal/renter_clients"}, // todo: temporary comment for rollback per story at https://www.pivotaltracker.com/story/show/178730113
        [RB_FEATURES.ACTIVITY_HUB] : {clickUrl: "/broker_portal/activity_hub"},
        [RB_FEATURES.LIGHT_SEARCH] : {clickUrl: "/bpl/search"},
        [RB_FEATURES.EDUCATION] : {clickUrl: '', open_in_new_tab: true},
        [RB_FEATURES.UPGRADE]: {clickUrl: '/broker_portal/upgrade', open_in_new_tab: true},
    };

    const getActivityHubCounter = (counter) => {
        return counter > 99 ? "99+" : counter
    };

    const initNavItems = async() => {
        const brokerId = parseInt(await getUserId());
        const applyNowEnabledRes = await applyNowAvailableApi.getApplyNowAvailable(props.partnerKey);
        const response = await clientActivityApi.get(brokerId);
        const redDotCounter = response.not_seen_count;
        const listingAlertsData = await listingWithAlertsApi.getAlertsCount(brokerId, props.partnerKey, 'active')
        const listingAlertsCounter = listingAlertsData?.alert_count
        const listingApplicationsData = await listingWithApplicationsApi.getApplicationsCount(brokerId)
        const listingApplicationsCounter = listingApplicationsData?.new_applications_count
        localStorage.setItem('applicationsCount', `${listingApplicationsCounter}`)

        props.features.some(featureName => {
            if (isClientFeature(featureName)) {
                defaultClientFeature = featureName;
                return true;
            }
        })
        const filteredFeatures: IFeatureLink [] = [...props.features, RB_FEATURES.UPGRADE].filter(featureName => {
            if (featureName === RB_FEATURES.APPLICATIONS ) {
                // Check for apply_now feature access
                return applyNowEnabledRes.apply_now_enabled
            } if (isClientFeature(featureName)) {
                return defaultClientFeature === featureName;
            } else {
                return Object.keys(featureLinks).includes(featureName)
            }
        }).map((featureName) => {
            if (isClientFeature(featureName)) {
                return {
                    clickUrl: featureLinks[defaultClientFeature].clickUrl,
                    component: redDotCounter > 0 ?
                        <div>
                            <span>Clients</span>
                            <div className="red-dot-header">{getActivityHubCounter(redDotCounter)}</div>
                        </div> :
                        <div>
                            <span>Clients</span>
                        </div>
                    ,
                    id: defaultClientFeature,
                }
            } else if (featureName === RB_FEATURES.EDUCATION) {
              return {
                    clickUrl: '/education', // just a flag
                    component: labels[featureName].label,
                    id: featureName,
                    open_in_new_tab: featureLinks[featureName].open_in_new_tab
              }
            } else if (featureName === RB_FEATURES.APPLICATIONS) {
                const applicationsCount = parseInt(localStorage.getItem('applicationsCount'), 10)

                return {
                    clickUrl: featureLinks[featureName].clickUrl,
                    component: applicationsCount > 0 ?
                        <div>
                            <span>Applications</span>
                            <div className="red-dot-header" id='applications_counter'>{getActivityHubCounter(applicationsCount)}</div>
                        </div> :
                        <div>
                            <span>Applications</span>
                        </div>
                    ,
                    id: featureName
                }
            } else if (featureName === RB_FEATURES.MY_LISTINGS) {
                return {
                    clickUrl: featureLinks[featureName].clickUrl,
                    component: listingAlertsCounter > 0 ?
                        <div>
                            <span>My Listings</span>
                            <div className="red-dot-header">{getActivityHubCounter(listingAlertsCounter)}</div>
                        </div> :
                        <div>
                            <span>My Listings</span>
                        </div>
                    ,
                    id: featureName
                }
            } else if (featureName === RB_FEATURES.UPGRADE) {
                return {
                    clickUrl: featureLinks[featureName].clickUrl,
                    component: 'UPGRADE',
                    id: featureName,
                    open_in_new_tab: featureLinks[featureName].open_in_new_tab,
                    is_modal: true
                }
            } else {
                return {
                    clickUrl: featureLinks[featureName].clickUrl,
                    component: labels[featureName].label,
                    id: featureName,
                    open_in_new_tab: featureLinks[featureName].open_in_new_tab
                }
            }
        });

        const agent_type = sessionStorage.getItem('access_level')
        const fullAgent = isFullAgent({ agent_type })     
        sessionStorage.setItem('partner_key',props.partnerKey);
        setPartnerKey(props.partnerKey); //RB-2160 - for partner_key to be set when a user is SSO'd in
        
        const filteredFeaturesWithoutUpgrade = filteredFeatures.filter(feature => (feature.component !== 'UPGRADE') && fullAgent)

        setAllowedFeatures(!fullAgent ? filteredFeatures : filteredFeaturesWithoutUpgrade);
        props.setMobileMenuItems(!fullAgent ? filteredFeatures : filteredFeaturesWithoutUpgrade);
    };

    //Filter available features from partner config
    useEffect(() => {
        if(props.features.length && Object.keys(labels).length) {
            initNavItems();
        }
    }, [props.features, labels]);

    const initPathName = () => {
        allowedFeatures.forEach(feature => {
            if(window.location.pathname.search(feature.clickUrl) >= 0) {
                setCurrentPath(feature.id)
            }
        })
    };

    const fetchLabels = async () => {
        const BPFeaturesRes = await featureSettingsApi.get([FeatureTypes.BP_FEATURE_SETTINGS]);
        setLabels(BPFeaturesRes.bp_feature_settings.menu_list);
    }

    const getTabClassName = (tab: string) => {
        return currentPath === tab ? "rb__nav--item active-tab" : "rb__nav--item";
    };

    useEffect(() => {
        const activePath = window.location.pathname.split('/')[2]
        sessionStorage.setItem('active_tab', activePath)
        fetchLabels();
    }, []);

    useEffect(() => {
        initPathName();
    }, [allowedFeatures]);

    const handlePreRequestClick = async (component) => {
        if (component === 'Education') {
            const educationLink = await handleEducationTabClick()
            window.open(educationLink, '_blank')
        }
    }

    const [showShowUpgradeModal, setShowUpgradePageModal] = useState<boolean>(false)

    const handleOpenModal = () => {
        setShowUpgradePageModal(!showShowUpgradeModal)
    }
    useEffect(() => {
        if (!showShowUpgradeModal) {
          document.body.style.overflow = 'visible'
          document.body.style.paddingRight = '0px'
        } 
    }, [showShowUpgradeModal])
    
    return <div className={`rb__nav--container ${props.className}`}>
        {
            allowedFeatures.map(feature => {
                if (feature.component === 'UPGRADE') {
                    return (
                        <a href={feature.clickUrl}
                            key={feature.id}
                            className='rb__nav--button_upgrade'
                            target={feature.open_in_new_tab ? "_blank" : "_self"}>{feature.component}
                        </a>
                    )
                } else {
                    return feature.component !== 'Education' ? (
                        <a href={feature.clickUrl}
                            key={feature.id}
                            className={getTabClassName(feature.id)}
                            target={feature.open_in_new_tab ? "_blank" : "_self"}>{feature.component}
                            </a>
                    ) : (
                        <div
                            key={feature.id}
                            onClick={() => handlePreRequestClick(feature.component)}
                            className='rb__nav--button'
                        >
                            {feature.component}
                        </div> 
                    )
                }
            })
        }
    </div>
}

interface DynamicNavProps {
    setMobileMenuItems: (args: HeaderMenuItem []) => any;
    className: string;
    features: RB_FEATURES [];
    partnerKey: string;
}
//RB-2160 - adding ability for partner_key to be set when a user is SSO'd in
const setPartnerKey = async (partner_key:string) => {
    const url = window.location.origin+'/set-partner-key/'+partner_key;
    return await axios.get(url);
}
