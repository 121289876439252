import { REST } from "../rest";
import { apiURL } from "../constants";
import { AgentType } from "../types";

export const agentInfoApi = {
  get: async (
    userId,
    validateToken = true,
    agentId = null,
    includeShowingTime = false
  ): Promise<IAgentInfoRes> => {
    const params = userId
      ? `user_id=${userId}&validate_token=${validateToken}&is_include_showing_time=${includeShowingTime}`
      : `agent_id=${agentId}&validate_token=${validateToken}&is_include_showing_time=${includeShowingTime}`;
    return await REST.get(`${apiURL}/v1/agent_info.json?${params}`, {}).then(
      (res) => {
        localStorage.setItem('partner_key', res.data.data.partner_key)
        return res.data.data;
      }
    );
  },
};

export interface IAgentInfoRes {
  locale_settings: any;
  opt_out_syndication: boolean;
  brokerage_name: string;
  broker_logo?: string;
  can_create_others: boolean;
  can_create_own: boolean;
  can_publish: boolean;
  display_logo_on_form: boolean;
  display_tile_on_app_form: boolean;
  email: string;
  full_name: string;
  header_logo: string;
  is_visible_cp: boolean;
  office_address: { address: string; city: string; state: string; zip: string };
  partner_key: string;
  phone: string;
  photo: string;
  super_region: number;
  lead_zip_code: string;
  owner_type: number;
  owner_id: number;
  agent_type: AgentType;
  agent_user_id: number;
  ext_agent_id: number;
  nrds_id: string;
  showing_time: any;
}
