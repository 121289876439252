import {REST} from "../../rest";

export const userApi = {
    put: async (params) => {
        const url = `/api/user/${params.id}`;
        console.log(url)
        return await REST.put(url, params).then(res => {
            return res.data;
        });
    }
};