import {REST} from "../rest";
import {apiURL} from "../constants";
import {RB_FEATURES} from "../../src/components/admin/types";
const API_URL = apiURL+'/v1';

export enum FeatureTypes {
    BP_FEATURE_SETTINGS = "bp_feature_settings"
}

export const featureSettingsApi = {
    get : async (featureList: FeatureTypes []): Promise<FeatureSettingsApiGetRes> => {

        return await REST.get(`${API_URL}/feature_settings.json`,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    feature_list: JSON.stringify(featureList)
                }
            }).then(res => {
            return res.data.data
        }).catch(e => {
            console.error(e);
        });
    }
};

export type FeaturesMenuList = {
    [key in RB_FEATURES]: {label: string}
}

export interface FeatureSettingsApiGetRes {
    bp_feature_settings: {
        menu_list: FeaturesMenuList
    }
}