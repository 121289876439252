import {getConsumerData} from "../api";
import {apiURL} from "../constants";
import {REST} from "../rest";

export const savedListingApi = {
    delete: async ({listingId, contextListings, activityType}) => {
        const consumerData = await getConsumerData();
        const userId = consumerData['id'];

        const formData  = new FormData();
        formData.append('listing_id', listingId);
        formData.append('consumer_id', userId.toString());
        formData.append('context_listings', `[${contextListings.join(',')}]`);
        formData.append('activity_type', activityType);
        return await REST.delete(`${apiURL}/v1/saved_listing.json`, {
            data: formData,
        });
    },
    put: async ({listingId, liked, contextListings}) => {
        const consumerData = await getConsumerData();
        const userId = consumerData['id'];

        const formData  = new FormData();
        formData.append('listing_id', listingId);
        formData.append('consumer_id', userId.toString());
        formData.append('context_listings', `[${contextListings.join(',')}]`);
        formData.append('activity_type', 'liked_listing');
        formData.append('liked', liked);

        return await REST.put(`${apiURL}/v1/saved_listing.json`,
            formData
            , {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    },
    post: async ({listingId, liked, contextListings}) => {
        const consumerData = await getConsumerData();
        const userId = consumerData['id'];

        const formData  = new FormData();
        formData.append('listing_id', listingId);
        formData.append('consumer_id', userId.toString());
        formData.append('context_listings', `[${contextListings.join(',')}]`);
        formData.append('activity_type', 'liked_listing');
        formData.append('liked', liked);

        return await REST.post(`${apiURL}/v1/saved_listing.json`,
            formData
            , {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    },
};