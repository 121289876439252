import React, {useState} from "react";
import "./mobile_nav.scss";
import {Drawer, List, ListItem} from '@material-ui/core';
import {HeaderMenuItem} from "../rb_modern_header";

export function MobileNav(props: {sections: HeaderMenuSection []}) {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <React.Fragment>
        <div className="mobile-nav--dropdown-container" onClick={handleClick}>
            <i className="fas fa-bars"/>
        </div>
            <Drawer
                open={open}
                // getContentAnchorEl={null}
                anchor={'right'}
                onClose={handleClose}
                // autoFocus={false}
                className="mobile-nav--drawer"
            >
                <List>
                    {
                        props.sections.map((section, index) => {
                            const items = section.items.map((menuItem, sectionIndex) => {
                                return <ListItem className="menu-item" key={sectionIndex}>
                                    <a href={menuItem.clickUrl}>{menuItem.component}</a>
                                </ListItem>
                            });

                            return <React.Fragment key={"sections-"+index}>
                                {items}
                                {
                                    section.items.length > 0 && index + 1 < props.sections.length ?
                                    <div className="mobile-nav--separator"/> : null
                                }
                            </React.Fragment>
                        })
                    }
                </List>
            </Drawer>
    </React.Fragment>
}

export interface HeaderMenuSection {
    items: HeaderMenuItem []
}